import Container from 'Components/Library/BaseComponents/Container';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { facilitationSelectors } from 'state/ducks/facilitation';
import ThumbIcon from '@mui/icons-material/ThumbUp';
import CandidateLabel from '../AiGroupingFlow/CandidateLabel';
import HelperText from 'Components/Common/HelperText';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import Button from 'Components/Library/BaseComponents/Button';

interface QuestionVoteResultsProps {
  sessionId: string;
  index: number;
  voteData: Array<any>;
  votingType: number;
}

function QuestionVoteResults(props: QuestionVoteResultsProps) {
  const { sessionId, index, voteData, votingType } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  if (!voteData || voteData.length === 0) {
    return (
      <HelperText key={`vote-inputs-${index}-no-answers`} name="no-answers-yet">
        {t('dfac.sessionnoanswerstovoteon')}
      </HelperText>
    );
  }
  const sorted = orderBy(voteData, ['votes'], 'desc');

  return (
    <>
      {sorted.map((item, answerIndex) => {
        if (answerIndex > 2 && !expanded) {
          return null;
        }
        return (
          <Container
            key={`dfac-results-question-${index}-answer-${answerIndex}`}
            direction="row"
            alignItems="stretch"
            sx={{ flexGrow: 10 }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mr={1.5}
            >
              <ThumbIcon color="secondary" fontSize="small" />
              <Box>
                <Typography color="secondary.main" variant="caption">
                  {item.votes}
                </Typography>
              </Box>
            </Box>
            {votingType === 1 ? (
              <CandidateLabel questionIndex={index} sessionId={sessionId} candidateItem={item} />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'normal',
                  overflow: 'hidden',
                  borderRadius: 1,
                  bgcolor: 'background.paper',
                  justifySelf: 'stretch',
                  flexGrow: 5,
                  lineHeight: '1.25rem',
                  padding: '12px',
                  paddingTop: '8ox',
                  paddingBottom: '8px',
                }}
              >
                {`${item.input}`}
              </Typography>
            )}
          </Container>
        );
      })}
      {voteData.length > 3 && (
        <Container direction="row" alignItems="stretch" sx={{ flexGrow: 10 }}>
          <Button
            key={`vote-inputs-expand-cta-${index}`}
            variant="text"
            name={expanded ? 'vote-inputs-collapse' : 'vote-inputs-expand'}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? t('dfac.collapsecta') : t('dfac.expandallcta')}
          </Button>
        </Container>
      )}
    </>
  );
}

export default QuestionVoteResults;
